import { useEffect } from "react";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import LibraryDrawer from "./LibraryDrawer";
import Menu from "./Menu";
import SceneDrawer from "./SceneDrawer";
import WebGLCanvas from "./WebGLCanvas";

import { useTheme } from "@mui/material";

import { Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useLoaderData } from "react-router-dom";
import { User } from "./utils/user";

const appbarHeight = isMobile ? 74.5 : 49.5;

const defaultDrawerWidth = 350;
const initialDrawerWidth = isMobile ? 0 : defaultDrawerWidth;

export type Access = "can view" | "can edit" | "no access";
export interface SceneMeta {
    realPath: string,
    name: string,
    access: Access,
}

export type FigurementAppProps = { sceneId: string, user: User, canEdit: boolean, themeMode: any, onThemeModeChange: any };

export default function FigurementApp({ sceneId, user, canEdit, themeMode, onThemeModeChange }: FigurementAppProps) {

    const [menuIconCommand, setMenuIconCommand] = useState();
    const [centerAndFit, setCenterAndFit] = useState(false);
    const [renderMode, setRenderMode] = useState();
    const handle = useFullScreenHandle();

    const [sceneDrawerWidth, setSceneDrawerWidth] =
        useState(initialDrawerWidth);
    const [libraryDrawerWidth, setLibraryDrawerWidth] =
        useState(initialDrawerWidth);
    const [showSceneDrawer, setShowSceneDrawer] = useState(
        initialDrawerWidth != 0
    );
    const [showLibraryDrawer, setShowLibraryDrawer] = useState(
        initialDrawerWidth != 0
    );

    const [sceneTree, setSceneTree] = useState();
    const [materialList, setMaterialList] = useState();
    const [cameraList, setCameraList] = useState();

    const [selectedNodes, setSelectedNodes] = useState([]); // list of strings - ints
    const [loadPrefabModelName, setPrefabModelName] = useState("");
    const [materialTypes, setMaterialTypes] = useState([]);

    const [activeUsersList, setActiveUsersList] = useState();
    const [filename, setFilename] = useState("Untitled");

    const [screenHeight, setScreenHeight] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0);

    const [breakPoint, setBreakPoint] = useState("");

    const [currentlyDraggedMaterial, setCurrentlyDraggedMaterial] = useState();

    const [materialThumbnail, setMaterialThumbnail] = useState();
    const [materialThumbnails, setMaterialThumbnails] = useState([]);

    const [wasmLoaded, setWasmLoaded] = useState(false);


    useEffect(() => {
        console.log("materialList update useEffect");

        if (showLibraryDrawer) {
            if (window.lys) window.lys.updateMaterialThumbnails();
        }
    }, [materialList]); //notice the empty array here

    const setSceneTreeAndSceneRoute = (tree) => {
        setSceneTree(tree);
    };

    const setLibraryDrawerVisibility = (visible) => {
        // is this one called on mobile?
        if (!visible) {
            setLibraryDrawerWidth(0);
        } else {
            if (breakPoint == "sm" || breakPoint == "xs") {
                setSceneDrawerVisibility(false);
            }
            setLibraryDrawerWidth(defaultDrawerWidth);
        }
        setShowLibraryDrawer(visible);
        console.log("show!!", visible);
    };

    const setSceneDrawerVisibility = (visible) => {
        // is this one called on mobile?
        if (!visible) {
            setSceneDrawerWidth(0);
        } else {
            if (breakPoint == "sm" || breakPoint == "xs") {
                setLibraryDrawerVisibility(false);
            }
            setSceneDrawerWidth(defaultDrawerWidth);
        }
        setShowSceneDrawer(visible);
    };

    const theme = useTheme();

    const handleResize = () => {
        let height = 0;
        let width = 0;

        if (isMobile) {
            // && CSS.supports("height: 100dvh")
            //`calc(100dvh - ${appbarHeight}px)`;
            width = document.documentElement.clientWidth;
            height = document.documentElement.clientHeight;
        } else {
            //`calc(100vh - ${appbarHeight}px)`;
            width = window.innerWidth;
            height = window.innerHeight;
        }

        setScreenHeight(height - appbarHeight + 1);
        setScreenWidth(width);

        let b = "";

        if (width <= theme.breakpoints.values.sm) {
            b = "xs";
        } else if (width <= theme.breakpoints.values.md) {
            b = "sm";
        } else {
            b = "md";
        }
        setBreakPoint(b);

        // console.log("height ", height-appbarHeight+1);
        // console.log("breakpoint ", b);
        // console.log("width ", width);

        // if (b=="sm" || b=="xs")

        if (showLibraryDrawer && showSceneDrawer && (b == "sm" || b == "xs")) {
            setLibraryDrawerVisibility(false);
        }
    };

    const handleFilenameChange = (name: string) => {
        if (name !== filename) {
            setFilename(name);
            window.lys.setSceneName(name);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {

            window.removeEventListener("resize", handleResize);
            console.log("FigurementApp unmount");
        };

    }, []); //notice the empty array here

    const sceneMeta = useLoaderData() as SceneMeta;
    useEffect(() => {
        setFilename(sceneMeta.name);
    }, [sceneId])

    return (
        <FullScreen handle={handle}>
            <Menu
                onFullScreen={handle}
                isfullScreen={handle.active}
                onIconClick={setMenuIconCommand}
                onCenterAndFitClick={setCenterAndFit}
                onRenderClick={setRenderMode}
                onSelectPrefabModel={setPrefabModelName}
                onShowLibraryDrawer={setLibraryDrawerVisibility}
                onShowSceneDrawer={setSceneDrawerVisibility}
                libraryDrawerOpen={showLibraryDrawer}
                sceneDrawerOpen={showSceneDrawer}
                user={user}
                sceneId={sceneId}
                activeUsersList={activeUsersList}
                sceneName={filename}
                onChangeSceneName={handleFilenameChange}
                themeMode={themeMode}
                canEdit={canEdit}
                onThemeModeChange={onThemeModeChange}
            />

            {!isMobileOnly && <Toolbar variant="dense" />}
            <Grid
                container
                sx={{ width: "100%", height: "100%", maxHeight: "100%" }}
            >
                {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}

                <Grid xs={12} sm={libraryDrawerWidth > 0 ? 6 : 12} md={"auto"}>
                    {showLibraryDrawer && canEdit && (
                        <LibraryDrawer
                            onDraggingMaterial={setCurrentlyDraggedMaterial}
                            height={screenHeight}
                            materialList={materialList}
                            materialThumbnails={materialThumbnails}
                            drawerWidth={
                                breakPoint == "md"
                                    ? libraryDrawerWidth
                                    : undefined
                            }
                            materialTypes={materialTypes}
                            onDrawerWidthChange={setLibraryDrawerWidth}
                            user={user}
                        />
                    )}
                </Grid>

                {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}
                <Grid
                    xs={12}
                    sm={sceneDrawerWidth > 0 || libraryDrawerWidth > 0 ? (canEdit ? 6 : 12) : 12}
                    md={true}
                >
                    {/* breakPoint=="md" || breakPoint=="sm" ? screenHeight : sceneDrawerWidth>0 || libraryDrawerWidth>0 ? screenWidth*(3/4) : screenHeight */}
                    <Box
                        height={
                            breakPoint == "md" || breakPoint == "sm"
                                ? screenHeight
                                : (((sceneDrawerWidth > 0 || libraryDrawerWidth > 0) && canEdit)
                                    ? screenHeight * 0.5
                                    : screenHeight)
                        }
                        sx={{ overflow: "hidden" }}
                    >
                        {!wasmLoaded && (
                            <Box
                                height={screenHeight}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                        <WebGLCanvas
                            A={sceneDrawerWidth + libraryDrawerWidth}
                            mouseMode={menuIconCommand}
                            centerAndFit={centerAndFit}
                            onCenterAndFitDone={setCenterAndFit}
                            render={renderMode}
                            selectedNodes={selectedNodes}
                            sceneId={sceneId}
                            onMaterialThumbnailUpdate={setMaterialThumbnail}
                            loadPrefabModelName={loadPrefabModelName}
                            user={user}
                            onWasmLoaded={setWasmLoaded}
                            onSceenTreeChange={setSceneTreeAndSceneRoute}
                            onMaterialListChange={setMaterialList}
                            onActiveUsersListChange={setActiveUsersList}
                            onCameraListChange={setCameraList}
                            setMaterialTypes={setMaterialTypes}
                            onNodeSelected={setSelectedNodes}
                            currentlyDraggedMaterial={currentlyDraggedMaterial}
                        />
                    </Box>
                </Grid>
                {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}
                <Grid xs={12} sm={6} md={"auto"}>
                    {showSceneDrawer && canEdit && (
                        <SceneDrawer
                            user={user}
                            // breakPoint=="xs" ? screenHeight-screenWidth*(3/4)-1 :
                            height={
                                breakPoint == "xs"
                                    ? screenHeight * 0.5
                                    : screenHeight
                            }
                            sceneTree={sceneTree}
                            cameraList={cameraList}
                            breakPoint={breakPoint}
                            drawerWidth={
                                breakPoint == "md"
                                    ? sceneDrawerWidth
                                    : undefined
                            }
                            onDrawerWidthChange={setSceneDrawerWidth}
                            onNodeSelected={setSelectedNodes}
                            selectedNodes={selectedNodes}
                            materialTypes={materialTypes}
                            sceneId={sceneId}
                        />
                    )}
                </Grid>
            </Grid>
            {isMobileOnly && <Toolbar variant="dense" />}
        </FullScreen>
    );
}
