import "@mui/material/styles/styled";
import { createRoot } from "react-dom/client";
import App from "./App";

import "./manifest.json";

import "./icon-128x128.png";
import "./icon-144x144.png";
import "./icon-152x152.png";
import "./icon-192x192.png";
import "./icon-384x384.png";
import "./icon-512x512.png";
import "./icon-72x72.png";
import "./icon-96x96.png";

// don't render if in a web worker
if (typeof window !== "undefined") {
  const container = document.getElementById("root");
  const root = createRoot(container); // createRoot(container!) if you use TypeScript

  root.render(
    <App />
  );
}
