import { randomKey } from "./crypto";

const animals = [
    'alligator', 'anteater', 'armadillo', 'auroch', 'axolotl', 'badger', 'bat', 'beaver', 'buffalo',
    'camel', 'chameleon', 'cheetah', 'chipmunk', 'chinchilla', 'chupacabra', 'cormorant', 'coyote',
    'crow', 'dingo', 'dinosaur', 'dog', 'dolphin', 'duck', 'elephant', 'ferret', 'fox', 'frog', 'giraffe',
    'gopher', 'grizzly', 'hedgehog', 'hippo', 'hyena', 'jackal', 'ibex', 'ifrit', 'iguana', 'koala', 'kraken',
    'lemur', 'leopard', 'liger', 'llama', 'manatee', 'mink', 'monkey', 'narwhal', 'nyan cat', 'orangutan'
].map((animal) => animal.charAt(0).toUpperCase() + animal.slice(1));

const adjectives = [
    'autumn', 'hidden', 'bitter', 'misty', 'silent', 'empty', 'dry', 'dark', 'summer', 'icy', 'delicate',
    'quiet', 'white', 'cool', 'spring', 'winter', 'patient', 'twilight', 'dawn', 'crimson', 'wispy', 'weathered',
    'blue', 'billowing', 'broken', 'cold', 'damp', 'falling', 'frosty', 'green', 'long', 'late', 'lingering',
    'bold', 'little', 'morning', 'muddy', 'old', 'red', 'rough', 'still', 'small', 'sparkling', 'throbbing',
    'shy', 'wandering', 'withered', 'wild', 'black', 'young', 'holy', 'solitary', 'fragrant', 'aged', 'snowy',
    'proud', 'floral', 'restless', 'divine', 'polished', 'ancient', 'purple', 'lively', 'nameless'
].map((adjective) => adjective.charAt(0).toUpperCase() + adjective.slice(1));

export function createTemporaryUserName(): string {
    return `${adjectives[Math.floor(Math.random() * adjectives.length)]} ${animals[Math.floor(Math.random() * animals.length)]}`;
}

/**
 * The user as stored in the browser.
 * Can be an anonymous user or a logged in user. Thus all the fields are optional.
 */
export class User {
    id?: string;
    email?: string;
    company?: string;
    name?: string;

    constructor() {
        this.name = createTemporaryUserName()
    }

    static randomKey() {
        return "us" + randomKey();
    }

}

export function isKnown(user: any): user is KnownUser {
    return !!user.id && !!user.email;
}


/**
 * Represents user known to the backend API.
 * Can not be anonymous.
 */
export class AuthenticatedUser {
    id: string;
    email: string;
    company?: string;
    name?: string;
}

export function isAuthenticatedUser(user: User): user is AuthenticatedUser {
    if (!user) return false;
    return !!user.id && !!user.email;
}

export function isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function getUserFromLocalStorage(): User {
    const userSrc = window.localStorage.getItem("user");
    if (!!userSrc) {
        return JSON.parse(userSrc);
    } 
    return null;
}
