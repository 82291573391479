import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import PartRightClickMenu from './PartRightClickMenu';

export default function SceneTreeElement({ node, onClick }) {

    const [mouseOver, setMouseOver] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        const x = event.clientX ;
        const y = event.clientY ;
        setRightClickMenuLocation([x,y]);
        event.preventDefault();
        event.stopPropagation();
    };


    const [rightClickMenuLocation, setRightClickMenuLocation] = useState(null);

    return (

        <Box sx={{ display: 'flex', alignItems: 'center', pt: 0.5, pb: 0.5, pr: 0, pl: 0 }}

            onMouseEnter={e => setMouseOver(true)} onMouseLeave={e => setMouseOver(false)}
        >

            <Typography
                flex="1 1 50%"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"


            >
                {node.name}
            </Typography>


            <Typography 
                flex="0 1 auto"
                color="inherit"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
            >
                {node.materialName ? node.materialName : ""}
            </Typography>

            {(mouseOver || isMobileOnly || open) &&
                <IconButton 
                    flex="0 1 auto"
                    sx={{ padding: 0, paddingLeft: 1, paddingRight: 1 }}
                  
                    onClick={handleClick}
                >
                    <MoreVertIcon fontSize='inherit' />
                </IconButton>
            }

        <PartRightClickMenu location={rightClickMenuLocation} handleClose={() => setRightClickMenuLocation(null) } />


        </Box>
    )
}
