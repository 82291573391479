import SearchIcon from "@mui/icons-material/SearchOutlined";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FTextField from "./FComponents/FTextField";
import ResizableContainer from "./ResizableContainer";

import FolderIcon from "@mui/icons-material/Folder";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import { useRef } from "react";

// Custom hook to observe component size
function useComponentSize(ref) {
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const handleResize = (entries) => {
            for (let entry of entries) {
                setSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height,
                });
            }
        };

        const observer = new ResizeObserver(handleResize);
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref]);

    return size;
}

export default function AssetManager({
    showFolders,
    recursive,
    filetypes: filetypes,
}: {
    showFolders: boolean;
    recursive: boolean;
    filetypes: string;
}) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newSelectedTab) => {
        setSelectedTab(newSelectedTab);
    };

    const handleMouseDown = (e) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        //document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        //document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const navigate = useNavigate();

    const handleClickOnThumbnail = (e, id) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/scenes/${id}/edit`);
    };

    // layout of images
    const imageContainerRef = useRef(null);
    const { width: imageContainerwidth } = useComponentSize(imageContainerRef);
    const [imageColumns, setImageColumns] = useState(1);
    useEffect(() => {
        setImageColumns(Math.floor(imageContainerwidth / 128));
    }, [imageContainerwidth]);

    const componentRef = useRef(null);
    const { width: componentWidth } = useComponentSize(componentRef);
    const [orientation, setOrientation] = useState("vertical");
    useEffect(() => {
        if (componentWidth > 600) setOrientation("horizontal");
        else setOrientation("vertical");
    }, [componentWidth]);

    const [folderTree, setFolderTree] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        // Fetch data from the server
        const url = "/api/folder-tree";

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((jsonData) => {
                setFolderTree(jsonData); // Store the JSON data in the state

                handleClickOnTreeViewItem(null, jsonData[0].id);

                setLoading(false); // Data fetching complete
            })
            .catch((error) => {
                setError(error); // Handle any errors
                setLoading(false);
            });
    }, []); // Empty dependency array ensures this effect runs once on mount

    useEffect(() => {
        handleClickOnTreeViewItem(null, selectedTreeFolder);
    }, [filetypes]); // Empty dependency array ensures this effect runs once on mount

    const [fileList, setFileList] = useState([]);
    const [folderList, setFolderList] = useState([]);

    const clickOnFolderIcon = async (event, id) => {
        handleClickOnTreeViewItem(event, id);
    };

    // Helper function to find parent IDs for a given node
    const findParentIds = (nodes, targetId, parentPath = []) => {
        for (const node of nodes) {
            if (node.id === targetId) {
                return parentPath;
            }
            if (node.children) {
                const path = findParentIds(node.children, targetId, [
                    ...parentPath,
                    node.id,
                ]);
                if (path) {
                    return path;
                }
            }
        }
        return "";
    };

    const [selectedTreeFolder, setSelectedTreeFolder] = useState<string>("");
    const [expandedTreeFolders, setExpandedTreeFolders] = useState<string[]>(
        [],
    );

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedTreeFolders(itemIds);
        event.stopPropagation();
    };

    const handleTreeExpansionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
        isExpanded: boolean,
    ) => {
        if (selectedTreeFolder != itemId)
            handleClickOnTreeViewItem(event, itemId);
    };

    // Function to handle tree item clicks
    const handleClickOnTreeViewItem = async (event, id) => {
        setSelectedTreeFolder(id);

        const newIds = Array.from(
            new Set([
                ...expandedTreeFolders,
                ...findParentIds(folderTree, id, []),
            ]),
        );

        setExpandedTreeFolders(newIds);

        const url = new URL("/api/file-listings", window.location.href);
        url.searchParams.append("path", id);
        if (recursive) url.searchParams.append("recursive", "true");
        url.searchParams.append("filetypes", filetypes);
        if (showFolders) url.searchParams.append("includeFolders", "true");

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((jsonData) => {
                if (showFolders) {
                    setFileList(jsonData.filter((obj) => obj.type != "folder")); // Store the JSON data in the state
                    setFolderList(jsonData.filter((obj) => obj.type != "file")); // Store the JSON data in the state
                } else {
                    setFileList(jsonData); // Store the JSON data in the state
                }
                setLoading(false); // Data fetching complete
            })
            .catch((error) => {
                setError(error); // Handle any errors
                setLoading(false);
            });
    };

    return (
        <>
            <Paper
                ref={componentRef}
                square={true}
                style={{ display: "flex", flexGrow: 1 }}
            >
                <Stack
                    direction="column"
                    style={{ display: "flex", flexGrow: 1 }}
                >
                    <FTextField
                        sx={{
                            padding: 1
                        }}
                        fullWidth
                        id="standard-bare"
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                    />

                    <Stack
                        direction="row"
                        sx={{ flexGrow: 1, overflow: "hidden" }}
                    >
                        <ResizableContainer
                            defaultSizes={[0.3, 0.7]}
                            orientation={orientation}
                        >
                            <RichTreeView
                                items={folderTree}
                                onSelectedItemsChange={
                                    handleClickOnTreeViewItem
                                }
                                selectedItems={[selectedTreeFolder]}
                                expansionTrigger="iconContainer"
                                expandedItems={expandedTreeFolders}
                                onExpandedItemsChange={
                                    handleExpandedItemsChange
                                }
                                onItemExpansionToggle={
                                    handleTreeExpansionToggle
                                }
                            />

                            <Box
                                ref={imageContainerRef}
                                sx={{ padding: 1, overflow: "auto" }}
                            >
                                {folderList.length > 0 && (
                                    <ImageList
                                        cols={imageColumns}
                                        gap={8}
                                    >
                                        {folderList.map((folder, index) => (
                                            <ImageListItem
                                                key={index}
                                                onClick={(e) =>
                                                    clickOnFolderIcon(
                                                        e,
                                                        folder.id,
                                                    )
                                                }
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}>
                                                    <FolderIcon
                                                        color="primary"
                                                        style={{
                                                            width: "100%",
                                                            flex: 1,
                                                        }}
                                                    />
                                                    <ImageListItemBar
                                                        title={folder.label}
                                                        position="below"
                                                    />
                                                </div>
                                                
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                )}

                                <ImageList cols={imageColumns} gap={8}>
                                    {fileList.map((file, index) => (
                                        <ImageListItem
                                            key={index}
                                            sx={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}>
                                                <img
                                                    draggable={true}
                                                    // onDragStart={(e) => dragStart(index)}
                                                    // onDragEnd={(e) => onDraggingMaterial(undefined)}
                                                    src={
                                                    "/api/thumbnail?id=" +
                                                    file.id
                                                    }
                                                    alt={file.label}
                                                    loading="lazy"
                                                onClick={(e) =>
                                                    handleClickOnThumbnail(
                                                        e,
                                                        file.id,
                                                    )
                                                }
                                                    id={file.id}
                                                    onError={(e) => {
                                                        e.target.onerror = null; // Prevents infinite loop if the fallback image also fails
                                                        e.target.src =
                                                            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjmDZt2n8ABkoCwgNiyRMAAAAASUVORK5CYII=";
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                />
                                                {file.label && (
                                                    <ImageListItemBar
                                                        title={file.label}
                                                        position="below"
                                                    />
                                                )}
                                            </div>
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                        </ResizableContainer>
                    </Stack>
                </Stack>
            </Paper>
        </>
    );
}
