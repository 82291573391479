import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {
  Fragment,
  StrictMode,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouteObject,
  RouterProvider,
  useParams
} from "react-router-dom";
import FigurementApp from "./FigurementApp";
import FilesPage from "./FilesPage";
import Login from "./Login";
import AdminUsersList from "./admin/AdminUsersList";
import ResetPassword from "./ResetPassword";
import { VerifyAccount } from "./VerifyAccount";
import { PaletteMode } from "@mui/material";
import { AuthenticatedUser, createTemporaryUserName, getUserFromLocalStorage, isAuthenticatedUser, User } from "./utils/user";


export default function App() {
  const [user, setUser] = useState<User | null>(null);
  const [mode, setMode] = useState("dark");
  const [installPrompt, setInstallPrompt] = useState<Event | undefined>(
    undefined,
  );
  const APP_INSTALL_DISMISSED_IN_LOCAL_STORAGE = "dismissedAppInstallation";

  const action = (
    <Fragment>
      <Button
        onClick={() => {
          if (installPrompt) {
            (installPrompt as any).prompt();
          }
        }}
        variant="contained"
        color="success"
      >
        Install App
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={(_e) => {
          window.localStorage.setItem(
            APP_INSTALL_DISMISSED_IN_LOCAL_STORAGE,
            "true",
          );
          setInstallPrompt(undefined);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const useAppTheme = (mode: PaletteMode) => {
    return useMemo(() => {
      const theme = createTheme({
        shape: {
          borderRadius: 0,
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              // Scrollbar styles based on theme mode
              body: {
                scrollbarColor:
                  mode === "dark" ? "#6b6b6b #2b2b2b" : "#b1b1b1 #ffffff",
                "&::-webkit-scrollbar": {
                  backgroundColor: mode === "dark" ? "#2b2b2b" : "#ffffff",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: mode === "dark" ? "#6b6b6b" : "#888",
                  minHeight: 24,
                  border: `2px solid ${mode === "dark" ? "#2b2b2b" : "#ffffff"}`,
                },
                "&::-webkit-scrollbar-thumb:focus": {
                  backgroundColor: mode === "dark" ? "#ffffff" : "#444",
                },
                "&::-webkit-scrollbar-thumb:active": {
                  backgroundColor: mode === "dark" ? "#ffffff" : "#444",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: mode === "dark" ? "#ffffff" : "#444",
                },
                "&::-webkit-scrollbar-corner": {
                  backgroundColor: mode === "dark" ? "#2b2b2b" : "#ffffff",
                },
              },
            },
          },
        },
        palette: {
          mode,
          neutralbackground: {
            main: "#1e1e1e",
            contrastText: "#fff",
          },
          ...(mode === "light"
            ? {
              primary: {
                main: "#0288d1",
              },
              secondary: {
                main: "#444444",
              },
              background: {
                default: "#eeeeee",
              },
            }
            : {
              primary: {
                main: "#0288d1",
              },
              secondary: {
                main: "#444444",
              },
              background: {
                default: "#eeeeee",
              },
            }),
        },
        typography: {
          fontSize: 10,
        },
      });

      return theme;
    }, [mode]);
  };

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    if (prefersDarkMode) setMode("dark");
    else setMode("light");
  }, [prefersDarkMode]);

  const handleLoggedIn = (user: AuthenticatedUser) => {
    router.navigate("/files");
    setUser(user);
  }

  useEffect(() => {
    /* code to prevent emscripten compiled code from eating key input */
    window.addEventListener(
      "keydown",
      function (event) {
        //console.log(event.keyCode);

        if (event.keyCode === 8 || event.keyCode === 9) {
          event.stopImmediatePropagation();
        }
      },
      true,
    );

    window.addEventListener(
      "keyup",
      function (event) {
        if (event.keyCode === 8 || event.keyCode === 9) {
          event.stopImmediatePropagation();
        }
      },
      true,
    );

    let user = getUserFromLocalStorage()

    if (user) {
      setUser(user);
    } else {
      setUser({
        name: createTemporaryUserName(),
      })
    }

    window.addEventListener("beforeinstallprompt", (e: Event) => {
      // Prevent the mini-infobar from appearing on mobile
      //e.preventDefault();
      // Stash the event so it can be triggered later.

      let appInstallDismissed = window.localStorage.getItem(
        APP_INSTALL_DISMISSED_IN_LOCAL_STORAGE,
      );
      if (!appInstallDismissed) {
        setInstallPrompt(e);
      }
    });

    window.addEventListener("appinstalled", () => {
      setInstallPrompt(undefined);
    });
  }, []);

  const theme = useAppTheme(mode);

  type SceneProps = {
    user: User;
    canEdit: boolean;
    themeMode: string;
    setMode: React.Dispatch<React.SetStateAction<string>>;
  };

  const SceneWrapper = ({ user, canEdit, themeMode, setMode }: SceneProps) => {
    const { id } = useParams();
    return (
      <FigurementApp
        sceneId={id}
        user={user}
        canEdit={canEdit}
        themeMode={themeMode}
        onThemeModeChange={setMode}
      />
    );
  };

  const authLoader = async () => {
    if (user && !isAuthenticatedUser(user)) {
      return redirect("/login");
    }
    return null;
  }

  const adminLoader = async () => {
    if (user && !isAuthenticatedUser(user)) {
      return redirect("/login");
    }
    if (user && !user.email.endsWith("@figurement.com")) {
      return redirect("/login");
    }
    return null;
  }

  const sceneLoader = async ({ params, request }) => {
    const response = await fetch(`/api/scenes/${params.id}/meta`);
    if (response.ok) {
      const meta = await response.json();
      const { access } = meta;
      const currentPath = new URL(request.url).pathname;
      const editPath = `/scenes/${params.id}/edit`;
      const viewPath = `/scenes/${params.id}/view`;

      if (
        access === "can edit" &&
        currentPath !== editPath &&
        currentPath !== viewPath
      ) {
        return redirect(editPath);
      }

      if (access === "can view" && currentPath !== viewPath) {
        return redirect(viewPath);
      }

      if (access === "no access") {
        return redirect("/login");
      }

      return meta;
    } else if (response.status === 401) {
      return redirect("/login");
    } else if (response.status === 404) {
      return redirect("/files");
    }
  };

  const routes: RouteObject[] = [
    {
      path: "/login",
      element: <Login onUser={handleLoggedIn} />,
    },
    {
      path: "/verify-account/:token",
      element: <VerifyAccount />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword onUser={handleLoggedIn} />,
    },
    {
      path: "/files",
      element: (isAuthenticatedUser(user) ? <FilesPage user={user} /> : <></>),
      loader: authLoader,
    },
    {
      path: "/scenes/:id/edit",
      element: (
        <SceneWrapper
          user={user}
          canEdit={true}
          themeMode={mode}
          setMode={setMode}
        />
      ),
      loader: sceneLoader,
    },
    {
      path: "/scenes/:id/view",
      element: (
        <SceneWrapper
          user={user}
          canEdit={false}
          themeMode={mode}
          setMode={setMode}
        />
      ),
      loader: sceneLoader,
    },
    {
      path: "/scenes/:id",
      loader: sceneLoader,
    },
    {
      path: "/admin/users",
      element: (isAuthenticatedUser(user) ? <AdminUsersList user={user} /> : <></>),
      loader: adminLoader
    },
    {
      path: "/",
      element: <Navigate to="/files" replace />,
      loader: authLoader,
    },
  ];

  const router = createBrowserRouter(routes);

  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Snackbar
          open={installPrompt != undefined}
          message={
            <>Want easy access to Figurement from your operating system?</>
          }
          action={action}
        />
        {/* https://web.dev/customize-install/ */}
        <RouterProvider router={router} />
      </ThemeProvider>
    </StrictMode>
  );
}
