import LockClosedIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from "react";

function DragLabel({ value, setValue, label }) {
    // We are creating a snapshot of the values when the drag starts
    // because the [value] will itself change & we need the original
    // [value] to calculate during a drag.
    const [snapshot, setSnapshot] = useState(value);
  
    // This captures the starting position of the drag and is used to
    // calculate the diff in positions of the cursor.
    const [startVal, setStartVal] = useState(0);
  
    // Start the drag to change operation when the mouse button is down.
    const onStart = useCallback(
      (event) => {
        setStartVal(event.clientX);
        setSnapshot(value);
      },
      [value]
    );
  
    // We use document events to update and end the drag operation
    // because the mouse may not be present over the label during
    // the operation..
    useEffect(() => {
      // Only change the value if the drag was actually started.
      const onUpdate = (event) => {
        if (startVal) {
          setValue(snapshot - (startVal-event.clientX)*0.1);
        }
      };
  
      // Stop the drag operation now.
      const onEnd = () => {
        setStartVal(0);
      };
  
      document.addEventListener("mousemove", onUpdate);
      document.addEventListener("mouseup", onEnd);
      return () => {
        document.removeEventListener("mousemove", onUpdate);
        document.removeEventListener("mouseup", onEnd);
      };
    }, [startVal, setValue, snapshot]);
  
    return (
      <InputAdornment
        onMouseDown={onStart}
        position="start"
        style={{
          color: "gray",
          cursor: "ew-resize",
          userSelect: "none",
        //   padding: "0px",
        //   margin: "0px"
        }}
      >
        {label}
      </InputAdornment>
    );
  }


  function NumberInput({ disabled, label, value, setValue }) {

    return (
        <TextField
   
            onChange={(e) => { setValue(e.target.value) }}
            value={value}

            sx={{ padding: 0, m: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
             
            // size='small'
            type="number"

            variant="standard"
            InputProps={{
                startAdornment: <DragLabel value={value} setValue={setValue} label={label} />,
                inputMode: 'decimal', maxLength: 13, step: "0.1"
            }}
        />

    );

  }




export default function TransformationProperties({ selectedNodes }) {

    const [translationX, setTranslationX] = useState(0);
    const [translationY, setTranslationY] = useState(0);
    const [translationZ, setTranslationZ] = useState(0);

    const [rotationX, setRotationX] = useState(0);
    const [rotationY, setRotationY] = useState(0);
    const [rotationZ, setRotationZ] = useState(0);

    const [scaleX, setScaleX_] = useState(0);
    const [scaleY, setScaleY_] = useState(0);
    const [scaleZ, setScaleZ_] = useState(0);

    const [lockScale, setLockScale] = useState(true);

    const setScaleX = x => { setScaleX_(x); if (lockScale) {setScaleY_(x);setScaleZ_(x)} }
    const setScaleY = y => { setScaleY_(y); if (lockScale) {setScaleX_(y);setScaleZ_(y)} }
    const setScaleZ = z => { setScaleZ_(z); if (lockScale) {setScaleY_(z);setScaleX_(z)} }

    const [synced, setSynced] = useState(false);

    // useEffect(() => {
    //     console.log("selected nodes",selectedNodes);
    //     if (window.lys)
    //         window.lys._setSelectedTransform(translationX,translationY,translationZ,rotationX,rotationY,rotationZ,scaleX,scaleY,scaleZ);
    //   },[translationX,translationY,translationZ,rotationX,rotationY,rotationZ,scaleX,scaleY,scaleZ]);

    const setSelectedTransform = (translationX, translationY, translationZ, rotationX, rotationY, rotationZ, scaleX, scaleY, scaleZ) => {
        console.log("setSelectedTransform",translationX, translationY, translationZ, rotationX, rotationY, rotationZ, scaleX, scaleY, scaleZ);
        if (window.lys)
            window.lys._setSelectedTransform(translationX, translationY, translationZ, rotationX, rotationY, rotationZ, scaleX, scaleY, scaleZ);

    }

    const round = (n) => {
        return Math.round(n * 10000.0) / 10000
    }

    useEffect(() => {

      if (synced)
       {
        setSelectedTransform(translationX, translationY, translationZ, rotationX, rotationY, rotationZ, scaleX, scaleY, scaleZ);
      }
    }, [translationX,translationY, translationZ, rotationX, rotationY, rotationZ,scaleX, scaleY, scaleZ ]);

    useEffect(() => {

      console.log("useEffect TransformationProperties 1");

        // called from C++ to set the transformation property.
    

        if (window.lys)
            window.lys._updateReactTransformationProperties(parseInt(selectedNodes[0])); // ensure that the above setReactTransformationProperties is called when reentereing this component

        console.log("useEffect TransformationProperties 2");

        //TODO: it's fugly that lys directly sets the state here instead of through the props system
    }, []);

    window['setReactTransformationProperties'] = function (tx, ty, tz, rx, ry, rz, sx, sy, sz) {

      setSynced(false);

      setTranslationX(round(tx));
      setTranslationY(round(ty));
      setTranslationZ(round(tz));

      setRotationX(round(rx));
      setRotationY(round(ry));
      setRotationZ(round(rz));

      setScaleX(round(sx));
      setScaleY(round(sy));
      setScaleZ(round(sz));

      setSynced(true);
  }


    return (
<>


        <Grid container spacing={0} padding={1} columns={12} alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={1}>

            <Grid item xs={3}><Typography align="left">Translation:</Typography></Grid>
            <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={translationX} setValue={setTranslationX} label="X" /></Grid>
            <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={translationY} setValue={setTranslationY} label="Y" /></Grid>
            <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={translationZ} setValue={setTranslationZ} label="Z" /></Grid>

            <Grid item xs={3}><Typography align="left">Rotation:</Typography></Grid>
            <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={rotationX} setValue={setRotationX} label="X" /></Grid>
            <Grid item xs={3}> <NumberInput disabled={selectedNodes.length != 1} value={rotationY} setValue={setRotationY} label="Y" /></Grid>
            <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={rotationZ} setValue={setRotationZ} label="Z" /></Grid>

            <Grid item xs={3}><Typography align="left">Scale: {lockScale ? <LockClosedIcon fontSize='6' onClick={e => setLockScale(false)} /> : <LockOpenIcon fontSize='6' onClick={e => setLockScale(true)} />}  </Typography></Grid>

            {lockScale &&
            <>
                <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={scaleX} setValue={setScaleX} label="S" /></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                </>
            }
            {!lockScale &&
            <>
            <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={scaleX} setValue={setScaleX} label="X" /></Grid>
            <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={scaleY} setValue={setScaleY} label="Y" /></Grid>
            <Grid item xs={3}><NumberInput disabled={selectedNodes.length != 1} value={scaleZ} setValue={setScaleZ} label="Z" /></Grid>
            </>
            }
        </Grid>
        </>
    );
}

        // <Grid container spacing={0} padding={1} columns={13} alignItems="center"



        //     justifyContent="center">


        //     <Grid item xs={1}></Grid >
        //     <Grid item xs={4}><Typography align="center">Position</Typography></Grid>
        //     <Grid item xs={4}><Typography align="center">Rotation</Typography></Grid>
        //     <Grid item xs={4}><Typography align="center">Scale {lockScale ? <LockClosedIcon fontSize='6' onClick={e => setLockScale(false)} /> : <LockOpenIcon fontSize='6' onClick={e => setLockScale(true)} />}  </Typography></Grid>

        //     <Grid item xs={1} >
        //         <Typography >X</Typography>
        //     </Grid>
        //     <Grid item xs={4}>

        //     <NumberInput disabled={selectedNodes.length != 1} value={translationX } setValue={setTranslationX} label="X"/>

        //     </Grid>
        //     <Grid item xs={4}>
        //         <TextField inputProps={{ inputMode: 'decimal', maxLength: 13, step: "1", style: { padding: 4 } }} 
        //         disabled={selectedNodes.length != 1} onChange={onChangeRotationX} value={rotationX} 
        //         sx={{ padding: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} margin="none" variant="standard" size='small' type="number" />
        //     </Grid>
        //     <Grid item xs={4}>
        //         <TextField inputProps={{ inputMode: 'decimal', maxLength: 13, step: "1", style: { padding: 4 } }} 
        //         disabled={selectedNodes.length != 1} onChange={onChangeScaleX} value={scaleX} 
        //         sx={{ padding: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} margin="none" variant="standard" size='small' type="number" />
        //     </Grid>

        //     <Grid item xs={1}>
        //         <Typography >Y</Typography>
        //     </Grid>
        //     <Grid item xs={4}>
        //         <TextField inputProps={{ inputMode: 'decimal', maxLength: 13, step: "1", style: { padding: 4 } }} 
        //         disabled={selectedNodes.length != 1} onChange={onChangeTranslationY} value={translationY} 
        //         sx={{ padding: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} margin="none" variant="standard" size='small' type="number" />
        //     </Grid>
        //     <Grid item xs={4}>
        //         <TextField inputProps={{ inputMode: 'decimal', maxLength: 13, step: "1", style: { padding: 4 } }}
        //          disabled={selectedNodes.length != 1} onChange={onChangeRotationY} value={rotationY}
        //           sx={{ padding: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}  margin="none" variant="standard" size='small' type="number" />
        //     </Grid>
        //     <Grid item xs={4}>
        //         <TextField inputProps={{ inputMode: 'decimal', maxLength: 13, step: "1", style: { padding: 4 } }} 
        //         disabled={selectedNodes.length != 1} onChange={onChangeScaleY} value={scaleY}
        //          sx={{ padding: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}  margin="none" variant="standard" size='small' type="number" />
        //     </Grid>

        //     <Grid item xs={1}>
        //         <Typography>Z</Typography>
        //     </Grid>
        //     <Grid item xs={4}>
        //         <TextField inputProps={{ inputMode: 'decimal', maxLength: 13, step: "1", style: { padding: 4 } }} 
        //         disabled={selectedNodes.length != 1} onChange={onChangeTranslationZ} value={translationZ} 
        //         sx={{ padding: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}  margin="none" variant="standard" size='small' type="number" />
        //     </Grid>
        //     <Grid item xs={4}>
        //         <TextField inputProps={{ inputMode: 'decimal', maxLength: 13, step: "1", style: { padding: 4 } }} 
        //         disabled={selectedNodes.length != 1} onChange={onChangeRotationZ} value={rotationZ} 
        //         sx={{ padding: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}  margin="none" variant="standard" size='small' type="number" />
        //     </Grid>
        //     <Grid item xs={4}>
        //         <TextField inputProps={{ inputMode: 'decimal', maxLength: 13, step: "1", style: { padding: 4 } }}
        //          disabled={selectedNodes.length != 1} onChange={onChangeScaleZ} value={scaleZ}
        //           sx={{ padding: 0, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} margin="none" variant="standard" size='small' type="number" />
        //     </Grid>
        // </Grid>



