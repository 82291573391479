import { useCallback, useEffect, useRef, useState } from 'react';

const useWebSocketChat = (sceneId: string | null) => {
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const wsRef = useRef<WebSocket | null>(null);
  const wsForSceneId = useRef<string | null>(null);

  const fetchChatHistory = useCallback(async () => {
    if (!sceneId) return;

    try {
      const response = await fetch(`/api/scenes/${sceneId}/chat/history`);
      if (!response.ok) {
        setError('Not able to connect to the chat at this time.');
        return;
      }
      const data = await response.json();
      setMessages(data.history);
    } catch (err) {
      setError(err.message);
    }
  }, [sceneId]);

  useEffect(() => {
    if (!sceneId) return;

    if (!wsRef.current) {
      wsRef.current = new WebSocket(`/api/scenes/${sceneId}/chat`);
      wsForSceneId.current = sceneId;

      wsRef.current.onopen = () => {
        setIsConnected(true);
        fetchChatHistory();
        setError(null)
      };

      wsRef.current.onmessage = (event) => {
        try {
          if (event.data === 'ping') {
            return;
          }
          const newMessage = JSON.parse(event.data);
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        } catch (e) {}
      };
    
      wsRef.current.onerror = (event) => {
        setError('Not able to connect to the chat at this time.');
      };

      wsRef.current.onclose = () => {
        setIsConnected(false);
      };
    }

    return () => {
      if (wsRef.current && wsForSceneId.current !== sceneId) {
        wsRef.current.close();
        wsRef.current = null;
        wsForSceneId.current = null;
      }
    };
  }, [sceneId, fetchChatHistory]);

  const sendMessage = useCallback((message) => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(message));
    } else {
      setError('Not able to send chat message at this time.');
    }
    setMessages((prevMessages) => [...prevMessages, message]);
  }, []);

  return { messages, sendMessage, error, isConnected };
};

export default useWebSocketChat;