import ModelIcon from "@mui/icons-material/Chair";
import ImageIcon from "@mui/icons-material/Image";
import EnvironmentIcon from "@mui/icons-material/Language";
import LightIcon from "@mui/icons-material/Lightbulb";
import ColorIcon from "@mui/icons-material/Palette";
import { Paper, Tab, Tabs } from "@mui/material";
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useState } from "react";
import AssetManager from "./AssetManager";

import MaterialIcon from "@mui/icons-material/SportsSoccer";

const minDrawerWidth = 200;
const maxDrawerWidth = 1000;

const dragger =
{
    width: "5px",
    cursor: "ew-resize",
    padding: "4px 0 0",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200,
    backgroundColor: "#ffffff",
    color: "#0000ff"
}

const tabFileType = 
[
    ".scene,.share.toml",
    ".materials",
    ".color",
    ".png,.jpg,.jpeg,.tga",
    ".environment",
    ".light"
]

    
export default function LibraryDrawer({
    drawerWidth,
    onDrawerWidthChange,
    height,
    materialList,
  }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [filetypes, setFiletypes] = useState(tabFileType[selectedTab]);

    const handleTabSelect = (event, newSelectedTab) => {

        setFiletypes(tabFileType[newSelectedTab]);
        setSelectedTab(newSelectedTab);
    };

    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        window.getSelection().removeAllRanges();


        const newWidth = e.clientX - document.body.offsetLeft;

        onDrawerWidthChange(newWidth);
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            onDrawerWidthChange(newWidth);

        }
    }, []);

    useEffect(() => {

    }, [])

    useEffect(() => {
        console.log("LibraryDrawer::useEffect materialList", materialList)
    }, [materialList])

    return (
        <Paper square={true} >



            <Stack
                width={drawerWidth}
                minWidth={350}
                height={height}
                maxHeight={height}
                minHeight={height}
                alignItems="stretch"
                justifyContent="space-between"
                direction="row"
            >


                <Stack sx={{ marginRight: "-3px", width: "100%" }}>
                    <Tabs
                        variant="fullWidth"
                        value={selectedTab}
                        onChange={handleTabSelect}
                        aria-label="icon tabs example"
                    >
                        <Tab
                            icon={<ModelIcon />}
                            aria-label="models"
                            label="Scenes"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<MaterialIcon />}
                            aria-label="materials"
                            label="Materials"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<ColorIcon />}
                            aria-label="colors"
                            label="Colors"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<ImageIcon />}
                            aria-label="images"
                            label="Textures"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<EnvironmentIcon />}
                            aria-label="environments"
                            label="Envs"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<LightIcon />}
                            aria-label="lights"
                            label="Lights"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                    </Tabs>
                    <AssetManager
                        showFolders={false}
                        recursive={true}
                        filetypes={filetypes}
                    />
                </Stack>
                <Stack onMouseDown={e => handleMouseDown(e)} sx={{ width: "3px", cursor: "ew-resize" }} ></Stack>
            </Stack>
        </Paper>

    )
}
