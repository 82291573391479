import { Paper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LysModule from "../.build/lys/wasm/bin/lys.mjs";
import "../.build/lys/wasm/bin/lys.wasm?init";
import PartRightClickMenu from "./PartRightClickMenu";
import type { MainModule } from "./typings/lys";

declare global {
  interface MainModuleWrapper {
    setFollowUserId: (userId: number) => void;
    importNamedModel: (modelName: string) => void;
    importFileModel: (modelName: string, x: number, y: number) => void;
    getMaterialNamesList: () => string;
    changeMaterialType: (materialName: string, type: number) => string;
    cloneSelectedNodes: () => void;
    deleteSelectedNodes: () => void;
    newFolderOnSelectedNodes: () => void;
    setUserName: (username: string) => void;
    getMaterialFromNodeId: (nodeId: number) => string;
    setMaterialParameters: (nodeId: number, parameters: string) => void;
    setMaterialName: (nodeId: number, name: string) => void;
    setPause: (pause: boolean) => void;
    setMaterialAtLocation: (materialId: number, x: number, y: number) => void;
    updateMaterialThumbnails: () => void;
    changeScene: (sceneId: string) => void;
    setSceneName(name: string): void;
    _quit: () => void;
  }

  interface Window {
    lys: MainModule & MainModuleWrapper;
    onSceneTreeUpdate: (treeStructure: string) => void;
    onMaterialListUpdate: (materialList: string) => void;
    onUsersListUpdate: (activeUsersList: string) => void;
    onCameraListUpdate: (cameraList: string) => void;
    onNodeSelected: (selectedNodes: string) => void;
    onMaterialThumbnailUpdate: (id: number, dataurl: string) => void;
    onMouseModeChange: (mode: string) => void;
  }
}

function WebGLCanvas({
  mouseMode,
  centerAndFit,
  onCenterAndFitDone,
  render,
  selectedNodes,
  onSceenTreeChange,
  onMaterialListChange,
  onActiveUsersListChange,
  onCameraListChange,
  user,
  setMaterialTypes,
  onNodeSelected,
  currentlyDraggedMaterial,
  onMaterialThumbnailUpdate,
  onWasmLoaded,
  sceneId,
  A,
}) {
  const [lys, setLys] = useState<MainModule & MainModuleWrapper>();
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [showMessage, setShowMessage] = useState(false);
  const initialized = useRef(false); // strict mode mounts components twice, wasm lys can't survice that
  const ref = useRef(null);
  const lysRef = useRef(lys);
  const lysFunctionCall = (mode) => {
    if (lys == undefined) return;

    switch (mode) {
      case "Center":
        lys._setCenterAndFit();
        ("tumble");
        break;
      case "Select":
        lys._setControlModeSelect();
        break;
      case "Tumble":
        lys._setControlModeTumble();
        break;
      case "Pan":
        lys._setControlModePan();
        break;
      case "Dolly":
        lys._setControlModeDolly();
        break;
      case "Zoom":
        lys._setControlModeZoom();
        break;
      default:
        console.log("unknown command " + mode);

      // code block
    }
  };

  useEffect(() => {
    if (lys == undefined || render === undefined) return;

    switch (render) {
      case "Wire":
        lys._setWireFrameRenderMode();
        break;
      case "Fast":
        lys._setRealTimeRenderMode();
        break;
      case "Photo":
        lys._setRaytraceRenderMode();
        break;
      default:
        console.log("unknown render mode " + render);
    }
  }, [render]);

  useEffect(() => {
    lysFunctionCall(mouseMode);
  }, [mouseMode]);

  useEffect(() => {
    if (lys == undefined) return;
    lys._setCenterAndFit();
    onCenterAndFitDone(false);
  }, [centerAndFit]);

  useEffect(() => {
    if (lys == undefined) return;
    // TODO: put this into treeview instead
    lys._resetNodeSelection();
    selectedNodes.forEach((elem) => lys._selectNode(parseInt(elem)));
    //todo send to C++ for selection
  }, [selectedNodes]);

  useEffect(() => {
    if (lys == undefined || !ref.current) return;
    const h = ref.current.parentElement.offsetHeight + 1;
    const w = ref.current.parentElement.offsetWidth + 1;

    console.log("resize (drawer change) " + h + " " + w);

    //ref.current.style.setProperty('height', h);
    //ref.current.style.setProperty('width', w);

    setWidth(w);
    setHeight(h);
    lys._setSize(w, h);
  }, [A]);

  const handleDragEnter = (e) => {
    console.log(e);
    // e.preventDefault();
    // e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    console.log(e);
    // e.preventDefault();
    // e.stopPropagation();
  };
  const handleDragOver = (e) => {
    console.log(e);
    // e.preventDefault();
    // e.stopPropagation();
  };
  const handleDrop = (e) => {
    if (currentlyDraggedMaterial != undefined) {
      // Get the bounding rectangle of target
      const rect = e.target.getBoundingClientRect();

      // Mouse position
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      window.lys.setMaterialAtLocation(currentlyDraggedMaterial.id, x, y);
      e.preventDefault(); // does nothing
      e.stopPropagation(); // does nothing
    }
    // e.preventDefault();
    // e.stopPropagation();
  };

  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxWidth: 800,
    width: "40%",
    zIndex: 100,
    p: 4,
  };

  const handleResize = () => {
    if (!window.lys || !ref.current) return;

    const h: number = ref.current.parentElement.offsetHeight + 1;
    const w: number = ref.current.parentElement.offsetWidth + 1;

    ref.current.style.setProperty("height", h);
    ref.current.style.setProperty("width", w);

    setWidth(w);
    setHeight(h);

    window.lys._setSize(w, h);
    window.lys.setPause(false);
  };

  const handleBlur = () => {
    if (!window.lys) return;

    window.lys.setPause(true); //Pause on loosing focus
  };
  const handleFocus = () => {
    if (!window.lys) return;

    window.lys.setPause(false);
  };

  const initializeLys = () => {
    LysModule({
      arguments: ["--open", sceneId],
      canvas: (() => document.getElementById("canvas"))(),
    }).then((instance: MainModule & MainModuleWrapper) => {
      console.log("WebGLCanvas component mounted");
      setLys(instance);
      window["lys"] = instance;
      instance["setFollowUserId"] = instance.cwrap("setFollowUserId", null, [
        "number",
      ]);
      instance["setSceneName"] = instance.cwrap(
        "setSceneName",
        null,
        ["string"],
      );
      instance["importNamedModel"] = instance.cwrap(
        "importNamedModel",
        null,
        ["string"],
      );
      instance["changeScene"] = instance.cwrap(
        "changeScene",
        null,
        ["string"],
      );
      instance["importFileModel"] = instance.cwrap("importFileModel", null, [
        "string",
        "number",
        "number",
      ]);
      instance["getMaterialNamesList"] = instance.cwrap(
        "getMaterialNamesList",
        "string",
        null,
      );
      instance["changeMaterialType"] = instance.cwrap(
        "changeMaterialType",
        "string",
        ["string", "number"],
      );
      instance["cloneSelectedNodes"] = instance.cwrap(
        "cloneSelectedNodes",
        null,
        null,
      );
      instance["deleteSelectedNodes"] = instance.cwrap(
        "deleteSelectedNodes",
        null,
        null,
      );
      instance["newFolderOnSelectedNodes"] = instance.cwrap(
        "newFolderOnSelectedNodes",
        null,
        null,
      );
      instance["setUserName"] = instance.cwrap("setUserName", null, [
        "string",
      ]);
      instance["getMaterialFromNodeId"] = instance.cwrap(
        "getMaterialFromNodeId",
        "string",
        ["number"],
      );
      instance["setMaterialParameters"] = instance.cwrap(
        "setMaterialParameters",
        null,
        ["number", "string"],
      );
      instance["setMaterialName"] = instance.cwrap("setMaterialName", null, [
        "number",
        "string",
      ]);
      instance["setPause"] = instance.cwrap("setPause", null, ["number"]);
      instance["setMaterialAtLocation"] = instance.cwrap(
        "setMaterialAtLocation",
        null,
        ["number", "number", "number"],
      );
      instance["updateMaterialThumbnails"] = instance.cwrap(
        "updateMaterialThumbnails",
        null,
        null,
      );

      //instance['getSceneTreeJSON']  =  instance.cwrap('getSceneTreeJSON','string');

      //const json = instance.getSceneTreeJSON();
      //onSceneTreeJSONChange(JSON.parse(json))
      //console.log(onSceneTreeJSONChange);
      if (ref.current) {
        const h: number = ref.current.parentElement.offsetHeight + 1;
        const w: number = ref.current.parentElement.offsetWidth + 1;

        ref.current.style.setProperty("height", h);
        ref.current.style.setProperty("width", w);

        setWidth(w);
        setHeight(h);
        instance._setSize(w, h);
      }

      instance.setUserName(user.name);

      setMaterialTypes(JSON.parse(window.lys.getMaterialNamesList()));

      window.addEventListener("resize", handleResize);
      window.addEventListener("blur", handleBlur);
      window.addEventListener("focus", handleFocus);

      window.lys.setPause(!document.hasFocus());

      onWasmLoaded(true);
    }).catch((error) => {
      if (error.name == 'ExitStatus')
        console.log("WASM exit gracefully");
      else if (error.name == "RuntimeError") {
        console.error("RuntimeError from Wasm: ", error);
      }
      else {
        console.error("Unexpected error during Wasm quit", error);
      }
    });
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      window.onSceneTreeUpdate = (treeStructure) => {
        onSceenTreeChange(JSON.parse(treeStructure));
        setShowMessage(false);
      };
      window.onMaterialListUpdate = (materialList) => {
        onMaterialListChange(JSON.parse(materialList));
      };
      window.onUsersListUpdate = (activeUsersList) => {
        onActiveUsersListChange(JSON.parse(activeUsersList));
      };
      window.onCameraListUpdate = (cameraList) => {
        onCameraListChange(JSON.parse(cameraList));
      };
      window.onNodeSelected = (selectedNodes) => {
        onNodeSelected(JSON.parse(selectedNodes));
      };
      window.onMaterialThumbnailUpdate = (id, dataurl) => {
        onMaterialThumbnailUpdate([id, dataurl]);
      };

      initializeLys();
    }
    return () => {
      console.log("WASM module will unmount");

      window.removeEventListener("resize", handleResize);
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);

      window.lys?._quit();
    };
  }, [])

  useEffect(() => {
    if (lys) {
      console.debug("Scene change to: ", sceneId, "but reusing lys.wasm");
      lys.changeScene(sceneId);
    }
  }, [sceneId]);

  const [rightClickMenuLocation, setRightClickMenuLocation] = useState(null);


  // Create a new left-click event
  const leftClickEvent = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window,
  });

  const handleRightClickMenuCLose = (e) => {
    setRightClickMenuLocation(null);
  }

  const handleRightClick = (event) => {

    // Find the target element or the element you want to dispatch the event to
    const targetElement = event.currentTarget; // or any specific element

    // Dispatch the left-click event
    targetElement.dispatchEvent(leftClickEvent);

    const x = event.clientX;
    const y = event.clientY;
    setRightClickMenuLocation([x, y]);
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <>
      {showMessage && (
        <Paper elevation={20} sx={style}>
          <h1>Welcome to Figurement</h1>
          Get started by <b>Importing</b> a CAD/3D file or <b>Insert</b> one of
          the prebuild geometry shapes from the <b>Menu</b>.
        </Paper>
      )}

      <canvas
        ref={ref}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        style={{
          boxSizing: "border-box",
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "#ff0000",
          margin: 0,
          display: "block",
          zIndex: 0,
          // width:"100%",
          // height:"100%",
          overflow: "hidden",
        }}
        width={width}
        height={height}
        onContextMenu={handleRightClick}
        className="App-renderwindow"
        id="canvas"
      />
      <PartRightClickMenu location={rightClickMenuLocation} handleClose={() => setRightClickMenuLocation(null)} />
    </>
  );
}
export default WebGLCanvas;
