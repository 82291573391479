import { Close, Link as LinkIcon, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Access, AccessMenu } from "./AccessMenu";
import { User } from "./utils/user";
import FButton from "./FComponents/FButton";
import FTextField from "./FComponents/FTextField";

type Share = {
  email: string;
  name: string;
  access: Access;
};

export type ShareDialogProps = {
  sceneId: string;
  user: User;
  open: boolean;
  onClose: () => void;
  fetchWithRetry: (url: string, options?: RequestInit) => Promise<Response>;
};

const ShareDialog = ({ sceneId, open, onClose, user }: ShareDialogProps) => {
  const [inputEmail, setInputEmail] = useState("");
  const [validEmail, setValidEmail] = useState<string | null>();
  const [loading, setLoading] = useState(true);
  const [shares, setShares] = useState<Share[]>([]);

  const handleInvite = async () => {
    console.log("Inviting:", validEmail);

    if (!validEmail) return;

    // POST to /api/scenes/[sceneId]/shares
    // with body { email: inviteEmail, access: 'can view' }
    await fetch(`/api/scenes/${sceneId}/shares`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: validEmail, access: "can edit" }),
    });
    setInputEmail("");
    setValidEmail(null);
    loadShares();
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCopyLink = () => {
    console.log("Copying link...");
    navigator.clipboard.writeText(window.location.href);
  };

  const handleAccessChange = async (share, newAccess: Access) => {
    share.access = newAccess;
    setShares([...shares]);

    await fetch(`/api/scenes/${sceneId}/shares`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: share.email, access: newAccess }),
    });

    loadShares();
  };

  const loadShares = () => {
    const url = `/api/scenes/${sceneId}/shares`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((jsonData) => {
        setShares(jsonData); // Store the JSON data in the state
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!open) return;
    loadShares();
  }, [open]); // Only run when dialog is open

  const handleEmailChanged = (email: string) => {
    setInputEmail(email);
    if (isValidEmail(email)) {
      setValidEmail(email);
    } else {
      setValidEmail(null);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="div">
          Share this scene
        </Typography>
        <Box>
          <Button
            variant="text"
            startIcon={<LinkIcon />}
            onClick={handleCopyLink}
            sx={{ mr: 1, textTransform: 'none' }}
          >
            Copy link
          </Button>
          <IconButton aria-label="close" onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      {loading && (
        <DialogContent>
          <Typography>Loading...</Typography>
        </DialogContent>
      )}
      {!loading && (
        <DialogContent>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginBottom: 2 }}
          >
            <Box sx={{ display: "flex", marginBottom: 2, marginTop: 2 }}>
              <FTextField
                fullWidth
                type="email"
           
                variant="outlined"
                placeholder="Invite others by name or email"
                value={inputEmail}
                onChange={(e) => handleEmailChanged(e.target.value)}
                onKeyUp={(e) => e.key === "Enter" && handleInvite()}
                sx={{ flexGrow: 1, marginRight: 1 }}
              />
              <FButton
                sx ={{margin:0}}
                onClick={handleInvite}
                disabled={!validEmail}
              >
                Invite
              </FButton>
            </Box>
            {/* <Typography
              variant="subtitle1"
              sx={{ marginBottom: 1, textAlign: "right", minHeight: "1.7em" }}
            >
              {validEmail}
            </Typography> */}
          </Box>
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Who has access
          </Typography>
          <List>
            {shares.map((share: Share) => (
              <ListItem key={share.email} disableGutters>
                <ListItemIcon  sx={{ minWidth: '36px' }}>
                  <Person />
                </ListItemIcon>
                <ListItemText primary={share.name} />
                <ListItemSecondaryAction >
                  <AccessMenu 
                    currentAccess={share.access}
                    onAccessChange={async (newAccess) =>
                      await handleAccessChange(share, newAccess)
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListItem disableGutters>
              <ListItemIcon  sx={{ minWidth: '36px' }}>
                <Person />
              </ListItemIcon>
              <ListItemText primary={`${user.name ?? user.email} (you)`} />
            </ListItem>
          </List>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ShareDialog;
