import { Box, Stack } from '@mui/material';
import { Fragment, Children, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';

export default function ResizableContainer({
  children,
  defaultSizes,
  orientation = 'vertical',
}) {
  const theme = useTheme(); // Access the theme
  const containerRef = useRef(null);
  const persistentSizesRef = useRef({});
  const childrenArray = Children.toArray(children);

  // Initialize sizes with defaultSizes or equally distribute if not specified
  const initialSizes = () => {
    if (defaultSizes && defaultSizes.length === childrenArray.length) {
      return defaultSizes;
    }

    const storedSizes = childrenArray.map((_, index) => {
      return persistentSizesRef.current[index] ?? 1 / childrenArray.length;
    });

    return storedSizes;
  };

  const [sizes, setSizes] = useState(initialSizes);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false);


  function normalizeArray(arr) {
    // Calculate the sum of all elements in the array
    const totalSum = arr.reduce((sum, num) => sum + num, 0);

    // If the total sum is zero, return an array of zeros to avoid division by zero
    if (totalSum === 0) {
        return arr.map(() => 0);
    }

    // Divide each element by the total sum to normalize
    return arr.map(num => num / totalSum);
}

  // Adjust sizes if the number of children changes
  useEffect(() => {
    
    if (childrenArray.length !== sizes.length) {
      let newSizes = childrenArray.map((_, index) => {
        return persistentSizesRef.current[index] ?? 1 / childrenArray.length;
      });

      newSizes = normalizeArray(newSizes);

      setSizes(newSizes);
    }
  }, [childrenArray.length, sizes.length]);

  const handleMouseDown = useCallback(
    (index, event) => {
      const startPosition =
        orientation === 'vertical' ? event.clientY : event.clientX;
      const startSizes = [...sizes];

      setIsDragging(true);

      const onMouseMove = (moveEvent) => {
        window.getSelection().removeAllRanges();
        const currentPosition =
          orientation === 'vertical' ? moveEvent.clientY : moveEvent.clientX;
        const delta =
          (currentPosition - startPosition) /
          (orientation === 'vertical'
            ? containerRef.current.clientHeight
            : containerRef.current.clientWidth);
        const newSizes = [...startSizes];
        newSizes[index] += delta;
        newSizes[index + 1] -= delta;

        // Constrain newSizes to stay within 0-1 range
        if (newSizes[index] < 0) {
          newSizes[index] = 0;
        }
        if (newSizes[index + 1] < 0) {
          newSizes[index + 1] = 0;
        }
        if (newSizes[index] + newSizes[index + 1] > 1) {
          const excess = newSizes[index] + newSizes[index + 1] - 1;
          newSizes[index + 1] -= excess;
        }

        setSizes(newSizes);
        persistentSizesRef.current = newSizes;
      };

      const onMouseUp = () => {
        setIsDragging(false);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [sizes, orientation]
  );

  const panelStyle = (size) => ({
    flexGrow: size,
    overflow: 'auto',
    minHeight: 0, // prevents flex items from collapsing
    flexBasis: 0, // ensures the panel sizes adjust correctly
  });

  const dividerStyle = (index) => ({
    height: orientation === 'vertical' ? '10px' : '100%',
    width: orientation === 'vertical' ? '100%' : '10px',
    cursor: orientation === 'vertical' ? 'ns-resize' : 'ew-resize',
    borderBottom: orientation === 'vertical' ? `1px solid ${theme.palette.divider}` : 'none', // Use theme divider color
    borderLeft: orientation === 'horizontal' ? `1px solid ${theme.palette.divider}` : 'none',  // Use theme divider color
    backgroundColor: isDragging
      ? 'transparent'
      : hoveredIndex === index
      ? '#d0e8ff'
      : 'transparent',
  });

  return (
    <Box
      ref={containerRef}
      sx={{ height: '100%', width: '100%' }}
    >
      <Stack
        direction={orientation === 'vertical' ? 'column' : 'row'}
        sx={{ height: '100%', width: '100%' }}
        spacing={0}
      >
        {childrenArray.map((child, index) => (
          <Fragment key={index}>
            <Box className="resizable-panel" sx={panelStyle(sizes[index])}>
              {child}
            </Box>
            {index < childrenArray.length - 1 && (
              <Box
                className="resizable-divider"
                sx={dividerStyle(index)}
                onMouseDown={(e) => handleMouseDown(index, e)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              />
            )}
          </Fragment>
        ))}
      </Stack>
    </Box>
  );
}
