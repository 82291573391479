import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect } from 'react';

export default function MaterialProperties({ selectedMaterial, materialTypes, onMaterialChange }) {

    useEffect(() => {
        console.log("selectedMaterial",selectedMaterial);
    }, [selectedMaterial])



    function handleParameterChange(value,index) {

        let newMaterial = JSON.parse(JSON.stringify(selectedMaterial)); //copy

        if(Number.isNaN(value)) value =0;
        newMaterial.parameters[index].value = value;
       

        onMaterialChange(newMaterial);

        window.lys.setMaterialParameters(newMaterial.id, JSON.stringify(newMaterial));

    }

    function setMaterialName(name)
    {
        let newMaterial = JSON.parse(JSON.stringify(selectedMaterial)); //copy
        newMaterial["name"] = name;
        
        onMaterialChange(newMaterial);
        window.lys.setMaterialName(newMaterial.id, name);
    }

    function handleMaterialTypeChange(type) {
        let newMaterial = window.lys.changeMaterialType(type,selectedMaterial.id);
     
        onMaterialChange(JSON.parse(newMaterial ));
        // TODO: can I free the string here?
    }

    const backgroundColor = (color) =>
    {
        const s = {background: '#ff0000', border: '1px solid #C4C4C4'};
        s.background=color;
        return s;
    }

    function paramToUI(entry,index) {

        switch (entry.type) {
            case 'double':
                {
                    return (
                        <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        
                        sx={{ paddingBottom: 1, paddingRight: 1, paddingLeft: 1 }}
                        key={index}
                    >
                            <Grid xs="auto">
                                {entry.name}
                            </Grid>

                            <Grid xs  sx={{ paddingLeft: 1, paddingRight: 1 }}>
                                <Slider
                                    size="small"
                                    style={{ width: '100%' }}
                                    value={ entry.value * 50 }
                                    onChange={(e => handleParameterChange(parseFloat(e.target.value / 50), index))}
                                    aria-labelledby="input-slider"
                                    sx={{MozUserSelect: "none",WebkitUserSelect: "none",msUserSelect: "none"}}

                                />
                            </Grid>

                            <Grid xs={4}>
                            <TextField
                                    margin="none"
                                    variant='outlined'
                                    type="number"
                                    size="small"
                                    fullWidth
                                    
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    
                                    value={entry.value}
                          
                                    onChange={(e => handleParameterChange(parseFloat( e.target.value),index))}
                                    onBlur={(e => handleParameterChange(parseFloat(e.target.value),index))}
                                    inputProps={{
                                        step: 0.01,
                                        min: 0,
                                        max: 2,
                                        inputMode: 'decimal',
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',

                                    }}
                                />

                                {/* <input
                                    type="color"
                                    value={entry.value}
                                    onChange={(e => handleParameterChange(e.target.value,index))}
                                /> */}
                            </Grid>
                            </Grid>
                 
                    )
                }
            case 'color':
                {
                    return (
                        <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ paddingBottom: 1, paddingRight: 1, paddingLeft: 1 }}
                        key={index}
                    >
                            <Grid key={entry.name} xs>
                                {entry.name}
                            </Grid>
                            <Grid xs={4} style={ backgroundColor(entry.value)} onClick={e => {(e.target.childNodes[0])?.focus(); (e.target.childNodes[0])?.click()}}>
                                <input
                                    style={{opacity:0}}
                                    type="color"
                                    value={entry.value}
                                    onInput={(e => handleParameterChange(e.target.value,index))}
                                />
                            </Grid>
                            </Grid>
                 
                    )
                }
            case 'texture':
                {
                    return (
                        <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ paddingBottom: 1, paddingRight: 1, paddingLeft: 1 }}
                        key={index}
                    >
                            <Grid key={entry.name} xs>
                                {entry.name}
                            </Grid>
                            <Grid xs={4} style={ backgroundColor(entry.value)} onClick={e => {(e.target.childNodes[0])?.focus(); (e.target.childNodes[0])?.click()}}>
                                <input
                                    style={{opacity:0}}
                                    type="color"
                                    value={entry.value}
                                    onInput={(e => handleParameterChange(e.target.value,index))}
                                />
                            </Grid>
                            </Grid>
                 
                    )

                }

        }


    }

    return (
   
        <Box > 
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: 1  }}
                
            >


                <Grid xs={2} sx={{ paddingBottom: 1 }}>
                    Name
                </Grid>
                <Grid xs={10} sx={{ paddingBottom: 1 }}>
                    <TextField
                        id="standard-basic"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!selectedMaterial}
                        hiddenLabel
                        InputLabelProps={selectedMaterial && { shrink: true }}
                        value={selectedMaterial && selectedMaterial.name}
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}}
                        onChange={e => setMaterialName(e.target.value)}
                    />
                </Grid>


                <Grid xs={8}>
                    Type
                </Grid>
                <Grid xs={4}>

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"

                        onChange={e => handleMaterialTypeChange(e.target.value)}
                        size="small"
                        value={selectedMaterial && selectedMaterial.type}
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{ width: '100%' }}
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}}
                 
                    >
                             
                        {materialTypes &&
                            materialTypes.map(
                                (name) =>
                                (
                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                )
                            )}


                    </Select>
                </Grid>
            </Grid>

            {selectedMaterial && selectedMaterial.parameters.map(
                (entry, index) =>
                (
                    paramToUI(entry, index)
                )

            )}
        
        </Box>



    );
};