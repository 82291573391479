import { Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { User } from "./utils/user";

export default function UserAvatar({ user }: { user: User }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    let navigate = useNavigate();

    const handleClose = (e) => {
        //onSelectPrefabModel(e.currentTarget.id);
        if (e.currentTarget.id == "logout") {
            fetch("/api/auth", { method: "DELETE" }).then(() => {
                window.localStorage.removeItem("user");
                navigate("/login");
            });
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <Tooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">
                            {user.name ?? user.email}
                        </Typography>
                    </React.Fragment>
                }
            >
                <Avatar
                    sx={{
                        color: "#ffffff",
                        bgcolor: "#334499",
                        width: 32,
                        height: 32,
                        marginRight: 1,
                        marginLeft: 1,
                        fontSize: "12px",
                        fontWeight: 300,
                    }}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={open ? handleClose : handleClick}
                >
                    {user.name?.charAt(0) ?? "G"}
                </Avatar>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                PaperProps={{
                    style: {
                        width: 150,
                    },
                }}
                anchorOrigin={
                    isMobileOnly
                        ? {
                              vertical: "top",
                              horizontal: "left",
                          }
                        : {
                              vertical: "bottom",
                              horizontal: "left",
                          }
                }
                transformOrigin={
                    isMobileOnly
                        ? {
                              vertical: "bottom",
                              horizontal: "left",
                          }
                        : {
                              vertical: "top",
                              horizontal: "left",
                          }
                }
            >
                <MenuItem id="account">
                    <ListItemText>Account</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        CTR+A
                    </Typography>
                </MenuItem>

                <MenuItem onClick={handleClose} id="logout">
                    <ListItemText>Log Out</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        CTR+L
                    </Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}
