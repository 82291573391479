import Button from '@mui/material/Button';
import PropTypes from "prop-types";

function MenuButton({ name, shortcut, onClick, icon, active, ...props }) {


    const Image = icon;

    const menuIconStyle = {
        marginLeft: 2,
    }

    const menuIconStyleSelected = {
        marginLeft: 2,
    }

    const menuButtonStyle =
    {
        border: '0px',
        minWidth: '52px',
        height: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        flexDirection: 'column',
        textTransform: 'none',
        color: '#999999',
        "&:hover": {
            backgroundColor: '#000000',
            color: '#eeeeee',
            border: '0px',
        }
    }

    const menuButtonStyleSelected =
    {
        border: '0px',
        minWidth: '52px',
        height: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        flexDirection: 'column',
        textTransform: 'none',
        backgroundColor: 'secondary.main',
        color: '#eeeeee',
        "&:hover": {
            backgroundColor: 'secondary.main',
            color: '#eeeeee',
            border: '0px',
        }
    }




    return (



        <Button
            variant="outlined"
            id={name}
            value={name}
            onClick={onClick}
            startIcon={<Image sx={active === name || active === true ? menuIconStyleSelected : menuIconStyle} />}
            sx={(active === name || active === true) ? menuButtonStyleSelected : menuButtonStyle}
            //sx={{ backgroundColor: 'secondary.main'}}
            {...props}
        >
            {name}
        </Button>
    )

}

MenuButton.propTypes = {
    Image: PropTypes.element
};

export default MenuButton;