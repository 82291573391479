import CubeIcon from '@mui-extra/icons/CubeOutlineIcon';
import BoxPlusIcon from '@mui/icons-material/AddBoxOutlined';
import CameraIcon from '@mui/icons-material/CameraAltOutlined';
import BoxMinusIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useEffect, useState } from "react";
import SceneTreeElement from './SceneTreeElement';

const getNodeIds = (nodes) => {
    let ids = [];

    nodes?.forEach(({ id, children }) => {
        ids = [...ids, id, ...getNodeIds(children)];
    });

    return ids;
};

  const nodeTypeToIcon = (typeName) =>
  {
    switch(typeName)
      {
          case "node":
              return undefined;
          case "camera":
              return CameraIcon;
          case "trimesh":
              return CubeIcon;
          default:
              return undefined;
      }
  }

export default function SceneTree({ sceneTree, selectedNodes, onNodeSelected }) {
    const [expandedNodes, setExpandedNodes] = useState([]);

    const handleNodeClick = (event, id) =>
    {
        const newArray = [...new Set([...selectedNodes, id])];
        onNodeSelected(newArray);  
    }

    const renderTree = (node) => {
        return (
            <TreeItem
                sx={{ p: 0 }}
                // TransitionProps={{
                //     timeout: 0
                // }}
                itemId={node.id}
                key={node.id}

                slots={{
                    endIcon: nodeTypeToIcon(node.type)
                  }}
            
                label={<SceneTreeElement node={node} onClick={ e=> handleNodeClick(e,node.id) }  />}
            >
                {Array.isArray(node.children)
                    ? node.children.map((n) => renderTree(n))
                    : null}
            </TreeItem>
        );
    };
   

    const handleNodesSelected = (event, nodes) => {
        onNodeSelected(nodes);
    }

    const findParentIdsForMultipleTargets = (nodes, targetIds) => {
        // Set to store unique parent IDs
        const parentIds = new Set();
    
        // Recursive function to find parent IDs for a given targetId
        const findParentIds = (nodes, targetId, parentPath = []) => {
            for (const node of nodes) {
                if (node.id === targetId) {
                    parentPath.forEach(id => parentIds.add(id));
                    return true; // Target found
                }
                if (node.children) {
                    const found = findParentIds(node.children, targetId, [...parentPath, node.id]);
                    if (found) {
                        return true; // Stop searching after finding the target
                    }
                }
            }
            return false; // Target not found in this path
        };
    
        // Iterate over each targetId and find its parent path
        targetIds.forEach(targetId => {
            findParentIds(nodes, targetId);
        });
    
        // Convert Set to Array before returning
        return Array.from(parentIds);
    };

    function unionOfArrays(array1, array2) {
        // Combine both arrays and create a Set to remove duplicates
        const unionSet = new Set([...array1, ...array2]);
    
        // Convert the Set back to an array
        return Array.from(unionSet);
    }

    useEffect(() => {
        if (sceneTree) {
            const parentIds = findParentIdsForMultipleTargets(sceneTree.children, selectedNodes);
            setExpandedNodes(unionOfArrays(parentIds,expandedNodes));
        }
    }, [selectedNodes, sceneTree]); // Empty dependency array ensures this effect runs once on mount


    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
      ) => {
        setExpandedNodes(itemIds);
        event.stopPropagation();
      };

  
    return (
        <>
            <SimpleTreeView
 
                onSelectedItemsChange={handleNodesSelected}
                selectedItems={selectedNodes}
                multiSelect

                expansionTrigger="iconContainer"
                expandedItems={expandedNodes}
                onExpandedItemsChange={handleExpandedItemsChange}
                // onItemExpansionToggle={handleExpansionToggle}
                
   
                slots={{
                    expandIcon: BoxPlusIcon,
                    collapseIcon: BoxMinusIcon
                  }}

                sx={{
                    flexGrow: 1,
                    maxWidth: 400,
                    overflow: "auto"
                }}
            >
                { sceneTree?.children?.map((node) => renderTree(node)) }
            </SimpleTreeView>

           
        </>
    );
}
