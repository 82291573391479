import { MenuOpen } from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import MenuButton from './MenuButton';

export default function PrefabMenu({}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const prefabNames =
    [
        "Cube",
        "Sphere",
        "Cylinder",
        "Disc",
        "Plane",
        "Cone",
    ]

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {

        if (prefabNames.find( name => name===e.currentTarget.id))
            window.lys.importNamedModel(e.currentTarget.id);

        setAnchorEl(null);
    };
 

    

    return (
        <div>

            <MenuButton
                name="Insert"
                tooltip="Import 3D/CAD files"
                icon={MenuOpen}
                id="basic-button"

                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={open ? handleClose : handleClick}
            />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                PaperProps={{
                    style: {
                        width: 150,
                    },
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {prefabNames.map(
                    (name) =>
                    (
                        <MenuItem key={name} onClick={handleClose} id={name}>
                            <ListItemText>{name}</ListItemText>
                            <Typography variant="body2" color="text.secondary">
                                CTRL+1
                            </Typography>
                        </MenuItem>

                    )

                )}


            </Menu>

        </div>
    );
}
