import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';



const PartRightClickMenu = ({ location, handleClose }) => {


    const handleClone = () => {
        window.lys.cloneSelectedNodes();
        handleClose();
    };

    const handleDelete = () => {
        window.lys.deleteSelectedNodes();
        handleClose();
    };

    const handleNewFolder = () => {
        window.lys.newFolderOnSelectedNodes();
        handleClose();
    };

    return (
        <Menu
            open={location != null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: location ? location[1] :0, left: location ? location[0] : 0 }
            }
        >
            <MenuItem onClick={handleClone}>Clone</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
            <MenuItem onClick={handleNewFolder}>New Folder</MenuItem>
        </Menu>
    );
};

export default PartRightClickMenu;